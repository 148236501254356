import React, { memo } from "react";
import { UseMutationResult } from "@tanstack/react-query";

import Modal from "../Modal";

interface RemoveModalProps {
  entityId: string;
  isOpen: boolean;
  name: string;
  removeFunc: UseMutationResult<any, unknown, any, unknown>;
  title: string;
  onHide: () => void;
}

const RemoveModal = memo(({ entityId, isOpen, name, removeFunc, title, onHide }: RemoveModalProps) => {
  const content = (
    <div className="items-center justify-between w-full">
      <div className="color-gray-700">
        Are you sure you want to remove <strong>{name}</strong>?
      </div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      title={title}
      content={content}
      includeCancelButton
      actionButton={{
        dataTestId: `${title}-remove-btn`,
        disabled: removeFunc.isLoading,
        label: "Remove",
        loading: removeFunc.isLoading,
        variant: "danger",
        clickHandler: () => removeFunc.mutate({ entityId }),
      }}
      onHide={onHide}
      onOpenChange={() => {}}
    />
  );
});

export default RemoveModal;
