import React, { memo, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";

import Modal from "../Modal";
import TextInput from "../TextInput";

interface RenameModalProps {
  entityId: string;
  isOpen: boolean;
  name: string;
  renameFunc: UseMutationResult<any, unknown, any, unknown>;
  title: string;
  onHide: () => void;
}

const RenameModal = memo(({ entityId, isOpen, name, renameFunc, title, onHide }: RenameModalProps) => {
  const [newName, setNewName] = useState(name);
  const content = (
    <div className="items-center justify-between w-full">
      <div className="color-gray-700">
        <div className="mb-5px">Name</div>
        <TextInput
          ariaLabel="new name"
          dataTestId="rename-modal-name-input"
          value={newName}
          onChange={(value: string) => setNewName(value)}
        />
      </div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      title={title}
      content={content}
      includeCancelButton
      actionButton={{
        dataTestId: `${title}-save-btn`,
        disabled: renameFunc.isLoading,
        label: "Update",
        loading: renameFunc.isLoading,
        variant: "primary",
        clickHandler: () => renameFunc.mutate({ fileId: entityId, name: newName }),
      }}
      onHide={onHide}
      onOpenChange={() => {}}
    />
  );
});

export default RenameModal;
