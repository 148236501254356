import React, { memo } from "react";

interface TextInputProps {
  ariaLabel?: string;
  dataTestId?: string;
  disabled?: boolean;
  icon?: React.JSX.Element;
  isError?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (_value: string) => void;
}

const TextInput = memo(
  ({
    ariaLabel,
    dataTestId,
    disabled = false,
    icon,
    isError = false,
    placeholder = "",
    value = "",
    onChange,
  }: TextInputProps) => {
    const bgColor = disabled ? "bg-gray-200" : "bg-white";
    const borderColor = isError ? "border-red-400" : "border-gray-300";
    const inputTextColor = disabled ? "color-gray-400" : "color-gray-900";
    return (
      <div
        className={`flex px-10px py-5px gap-x-5px items-center m-none w-full overflow-hidden box-border rounded border border-solid ${bgColor} ${borderColor}`}
      >
        {icon || null}
        <input
          aria-label={ariaLabel}
          data-testid={dataTestId}
          disabled={disabled}
          placeholder={placeholder}
          className={`p-none text-md w-full h-33px border-none focus:outline-none ${bgColor} ${inputTextColor}`}
          value={value}
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
        />
      </div>
    );
  },
);

export default TextInput;
