import { LazyArtifact, LazyArtifactPage, LazyModel, LazyModelPage, LazyWasmRevision } from "@istari/istari-client";
import { AccessRelationship, PatchOp } from "@istari/istari-client/dist/src/openapi";
import { WasmProperties } from "@istari/istari-wasm";
import { useMutation, useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { showToast } from "../components";
import { HttpStatusCodeType } from "../enums";
import { sortArrayByKeyAndOrder } from "../utils/array";
import { getFileNameAndExtension } from "../utils/file";
import clientSDK from "./clientSDK";

export interface Model {
  artifacts: [];
  file: {
    revisions: LazyWasmRevision[];
    id: string;
    version?: string | undefined;
  };
  id: string;
}

export type ModelProperties = {
  displayFileName?: string | undefined;
  extension: string | undefined;
};

export interface ModelData {
  model: {
    artifacts: LazyArtifact[];
    file: {
      revisions: LazyWasmRevision[];
      id: string;
      version?: string | undefined;
    };
    id: string;
  };
  modelContent?:
    | {
        bytes: number[] | undefined; // ArrayBuffer;
      }
    | undefined;
  modelProperties: {
    description: string | undefined;
    extension: string | undefined;
    mime: string | undefined;
    fileName: string;
    size: number;
    versionName: string | undefined;
  };
}

export type ModelArtifact = LazyArtifact & {
  extension: string;
  id: string;
  fileName: string;
  artifactAccessList: AccessRelationship[];
};

export interface ListOptions {
  size: number;
  page: number;
  sort: string | undefined;
  createBy?: string;
}

interface RenameModelPayload {
  fileId: string;
  name: string;
}

export const modelQueryKeys = {
  all: [],
  item: (id: string) => [...modelQueryKeys.all, id],
  list: (filter: ListOptions) => [...modelQueryKeys.all, "list", filter],
  tasks: (modelId: string) => [...modelQueryKeys.item(modelId), "tasks"],
  artifacts: (modelId: string, filter: ListOptions) => [...modelQueryKeys.item(modelId), "artifacts", filter],
};

export const artifactQueryKeys = {
  all: ["artifacts"],
  item: (id: string) => [...artifactQueryKeys.all, id],
};

export interface ModelProps {
  id: string;
  created: string;
  description: string;
  extension: string;
  external_identifier: string | undefined;
  mime: string | undefined;
  name: string;
  size: number;
  version_name: string | undefined;
}

export interface ModelType extends LazyModel, WasmProperties {
  created: string;
  displayFileName?: string;
  modelAccessList: AccessRelationship[];
}

export const useGetModels = ({ filter }: { filter: ListOptions }) =>
  useQuery(modelQueryKeys.list(filter), () =>
    clientSDK
      .listModelsLazy(filter.page, filter.size, filter.sort, filter.createBy)
      .then(async (data: LazyModelPage) => {
        const list = data.items.map(async (model: LazyModel) => {
          const modelPropsData: any[] = [];
          const modelAccessList = await clientSDK.access.listModelAccess({ modelId: model.id });

          const properties = await model.readCurrentProperties();

          modelPropsData.push({
            ...model,
            ...properties,
            id: model.id,
            created: model.file.revisions[0].created,
            displayFileName: properties.displayName || getFileNameAndExtension(properties.fileName)?.name,
            modelAccessList,
          });

          return modelPropsData;
        });

        const result = await Promise.all(list).then((response) => response.flat());

        return {
          items: result,
          total: data.total,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading models", error);
        showToast("An error occurred while loading the models", "error");
        return {
          items: [],
          total: 0,
        };
      }),
  );

export const useGetModel = (modelId: string) =>
  useQuery(modelQueryKeys.item(modelId), () =>
    clientSDK
      .getModelLazy(modelId)
      .then(async (model: LazyModel) => {
        const modelProperties = await model.readCurrentProperties();
        const modelAccessList = await clientSDK.access.listModelAccess({ modelId: model.id });

        const revs = model.file.revisions.map(async (rev: LazyWasmRevision) => {
          const revProp = await rev.readProperties();
          return { ...rev, ...revProp };
        });

        const modelPropertiesWithDisplayName = {
          ...modelProperties,
          displayFileName: modelProperties.displayName || getFileNameAndExtension(modelProperties.fileName)?.name,
        };

        const modelFullRevisions = await Promise.all(revs);

        const modelContent = await model.readCurrentContent();

        return {
          model,
          modelAccessList,
          modelContent,
          modelFullRevisions: sortArrayByKeyAndOrder(modelFullRevisions, "created", "desc"),
          modelProperties: modelPropertiesWithDisplayName,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading model", error);
        if (
          error.message.includes(String(HttpStatusCodeType.Forbidden)) ||
          error.message.includes(String(HttpStatusCodeType.NotFound))
        ) {
          throw new Error("This model is not found or you do not have permission to view this model.");
        } else {
          throw new Error("An error occurred while loading the model", error);
        }
      }),
  );

interface UploadModelVersionPayloadType {
  modelId: string;
  file: File;
  description?: string;
  versionName?: string;
  externalIdentifier?: string;
}

export const useUploadModelVersion = () =>
  useMutation((data: UploadModelVersionPayloadType) =>
    clientSDK.updateModelLazy(data.modelId, data.file, [], data.description, undefined, data.externalIdentifier),
  );

export const downloadFile = (modelContentBytes: number[], name: string | undefined, extension: string | undefined) => {
  if (modelContentBytes && name && extension) {
    const blob = new Blob([new Uint8Array(modelContentBytes)]);
    saveAs(new Blob([blob], { type: extension }), `${name}.${extension}`);
    showToast(`${name}.${extension} has been downloaded successfully.`, "success");
  } else {
    showToast("An error occurred while downloading the file.", "error");
  }
};

type ShareModelAccessRelationship = {
  accessRelationship: AccessRelationship;
  patchOpType: PatchOp;
};

export interface ShareFilePayload {
  modelId: string;
  accessGrants: ShareModelAccessRelationship[];
  resourceType: "model" | "artifact";
}

export const useShareFile = () =>
  useMutation(async (data: ShareFilePayload) => {
    let promises;
    if (data.resourceType === "model") {
      promises = data.accessGrants.map((item) =>
        clientSDK.access.patchModelAccess({
          modelId: data.modelId,
          accessRelationship: item.accessRelationship,
          patchOp: item.patchOpType,
        }),
      );
    } else {
      promises = data.accessGrants.map((item) =>
        clientSDK.access.patchArtifactAccess({
          artifactId: data.modelId,
          accessRelationship: item.accessRelationship,
          patchOp: item.patchOpType,
        }),
      );
    }
    await Promise.all(promises);
  });

type ExtractModelPayload = {
  functionName: string;
  modelId: string;
  operatingSystem: string;
  toolKey: string;
  toolVersion: string;
};

export const useExtractModel = () =>
  useMutation((data: ExtractModelPayload) =>
    clientSDK.addJobLazy(
      data.modelId,
      new File(["sample"], "sample.txt"),
      data.functionName,
      data.toolKey,
      data.toolVersion,
      data.operatingSystem,
    ),
  );

export interface ArtifactType extends LazyArtifact, WasmProperties {
  created: string;
  artifactAccessList: AccessRelationship[];
  id: string;
  model_id: string;
  displayFileName?: string;
}

export const useGetArtifactsByModelId = ({ modelId, filter }: { modelId: string; filter: ListOptions }) =>
  useQuery(modelQueryKeys.artifacts(modelId, filter), () =>
    clientSDK
      .listArtifactsLazy(modelId, filter.page, filter.size, filter.sort)
      .then(async (data: LazyArtifactPage) => {
        const list = data.items.map(async (artifact: LazyArtifact) => {
          const modelPropsData: any[] = [];
          const artifactAccessList = await clientSDK.access.listArtifactAccess({ artifactId: artifact.id });

          const properties = await artifact.readCurrentProperties();
          modelPropsData.push({
            ...artifact,
            ...properties,
            id: artifact.id,
            created: artifact.file.revisions[0].created,
            artifactAccessList,
            model_id: modelId,
          });
          return modelPropsData;
        });

        const result = await Promise.all(list).then((response) => response.flat());

        return {
          items: result,
          total: data.total,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading artifacts", error);
        showToast("An error occurred while loading the artifacts", "error");
        return {
          items: [],
          total: 0,
        };
      }),
  );

export const useGetArtifact = (artifactId: string) =>
  useQuery(artifactQueryKeys.item(artifactId), () =>
    clientSDK
      .getArtifactLazy(artifactId)
      .then(async (artifact: LazyArtifact) => {
        const artifactProperties = await artifact.readCurrentProperties();
        const artifactAccessList = await clientSDK.access.listArtifactAccess({ artifactId: artifact.id });

        const artifactContent = await artifact.readCurrentContent();

        return {
          artifact,
          artifactAccessList,
          artifactContent: artifactContent.bytes,
          displayFileName: artifactProperties.displayName || getFileNameAndExtension(artifactProperties.fileName)?.name,
          artifactProperties,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading artifact", error);
        if (
          error.message.includes(String(HttpStatusCodeType.Forbidden)) ||
          error.message.includes(String(HttpStatusCodeType.NotFound))
        ) {
          throw new Error("This artifact is not found or you do not have permission to view this artifact.");
        } else {
          throw new Error("An error occurred while loading the artifact", error);
        }
      }),
  );

export const useRenameModel = () =>
  useMutation((data: RenameModelPayload) => clientSDK.updateFileDisplayName(data.fileId, data.name));

export type JobItemType = {
  comments: [];
  created: string;
  created_by_id: string;
  file: {
    created: string;
    created_by_id: string;
    id: string;
    resource_id: string;
    resource_type: string;
    revisions: [];
  };
  id: string;
  model_id: string;
  status_history: {
    created: string;
    created_by_id: string;
    id: string;
    job_id: string;
    name: string;
  }[];
};

export const useListModelJobs = (modelId: string) =>
  useQuery(modelQueryKeys.tasks(modelId), () =>
    clientSDK
      .listJobsLazy(modelId)
      .then((data) => ({
        items: data.items,
      }))
      .catch((error: Error) => {
        console.error("Error loading model jobs", error);
        showToast("An error occurred while loading the model jobs", "error");
        return [];
      }),
  );
